import React, { Component } from "react";

import {
  Box,
  Avatar,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import appearance from "../../services/appearance";

const styles = (theme) => ({
  contestantWon: {
    backgroundColor: fade(appearance.colors.green.import[500], 0.4),
  },
  eliminated: {
    backgroundColor: fade(appearance.colors.red.import[500], 0.2),
  },
  image: {
    marginRight: theme.spacing(1),
  },
});

class WinnerCell extends Component {
  constructor(props) {
    super(props);
  }

  getWinnerBackgroundClassName = (isWinner, isEliminated) => {
    const { classes } = this.props;

    if (isWinner) {
      return classes.contestantWon;
    } else if (isEliminated) {
      return classes.eliminated;
    }

    return "";
  };

  render() {
    // Styling
    const { classes } = this.props;

    const {
      winner,
    } = this.props;

    const {
      name,
      icon_path,
      isEliminated,
    } = winner;

    return (
      <Box
        display="flex"
        alignItems="center"
      >
        {name &&
          <>
            <img
              className={classes.image}
              src={(`/assets/icons/${icon_path}`)}
              height={16}
              width={16}
            />
            {isEliminated && (
              <s>{name}</s>
            )}

            {!isEliminated && (
              name
            )}
          </>
        }
        {!name &&
          <Box>
            No winner picked! 
          </Box>
        }
      </Box>
    )
  }
}

export default withStyles(styles)(WinnerCell);
