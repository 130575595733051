import React, { Component } from "react";

import {
  Box,
  Paper,
  Typography,
  Grid,
  Hidden,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

import { makeStyles, } from "@material-ui/core/styles";

import appearance from "../../services/appearance";

import { get } from "lodash";

import TeamRow from "../TeamRow";
import PickRow from "../PickRow";

const loserFontColor = "#999";

const useStyles = makeStyles({
  formControlLabel: {
    "&.MuiFormControlLabel-root": {
      width: "100%",
    },
    "& .MuiFormControlLabel-label": {
      flexGrow: 1,
    },
  },
});

const Game = (props) => {
  const classes = useStyles();
  const {
    bracketLinesClassName,
    game,
    pick,
    team1,
    team2,
    team1Picked,
    team2Picked,
    team1PickedId,
    team2PickedId,
    isFirstRound,
    isFirstFour,
    handleAnswerChange,
    firstFourPick,
    disabled,
  } = props;

  const {
    id,
    team1Id,
    team2Id,
    team1Score,
    team2Score,
    isComplete,
    isUpset,
    isLocked,
    startDate,
    startTime,
  } = game;

  const {
    winner: pickedWinnerId,
  } = pick;

  const actualWinnerId = !isComplete || (team1Score === 0 && team2Score === 0) ? undefined : team1Score > team2Score ? team1Id : team2Id;
  const firstFourDisabled = isLocked || disabled;

  if (isFirstRound) {
    return (
      <Paper>
        <Box p={1}>
          <TeamRow
            team={team1}
            score={team1Score}
            isWinner={actualWinnerId === team1Id}
            isPickedToWin={pickedWinnerId === team1Id}
            isUpset={isUpset}
            isComplete={isComplete}
            isFirstRound
          />
          <TeamRow
            team={team2}
            score={team2Score}
            isWinner={actualWinnerId === team2Id}
            isPickedToWin={pickedWinnerId === team2Id}
            isUpset={isUpset}
            isComplete={isComplete}
            isFirstRound
          />
        </Box>
      </Paper>
    );
  }
  else if (isFirstFour) {
    return (
      <Paper>
        <Box
          py={1}
          pl={1}
          pr={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControl component="fieldset" disabled={firstFourDisabled}>
            <RadioGroup
              aria-label="prediction-question"
              defaultValue={""}
              value={firstFourPick || ""}
              name="radio-buttons-group"
              onChange={(event) => handleAnswerChange(id, event.target.value)}
            >
            <Box
              display="flex"
              justifyContent="space-between"
            >
              <FormControlLabel
                className={classes.formControlLabel}
                key={team1Id}
                value={team1Id}
                control={<Radio size="small" />}
                labelPlacement="start"
                disabled={firstFourDisabled}
                label={
                  <Box flexGrow="1">
                    <TeamRow
                      team={team1}
                      score={team1Score}
                      isWinner={actualWinnerId === team1Id}
                      isPickedToWin={pickedWinnerId === team1Id}
                      isUpset={isUpset}
                      isComplete={isComplete}
                      isFirstFour
                    />
                  </Box>
                }
              />
            </Box>
          <Box
            display="flex"
            justifyContent="space-between"
          >
              <FormControlLabel
                className={classes.formControlLabel}
                key={team2Id}
                value={team2Id}
                control={<Radio size="small" />}
                labelPlacement="start"
                disabled={firstFourDisabled}
                label={
                  <Box flexGrow="1">
                    <TeamRow
                      team={team2}
                      score={team2Score}
                      isWinner={actualWinnerId === team2Id}
                      isPickedToWin={pickedWinnerId === team2Id}
                      isUpset={isUpset}
                      isComplete={isComplete}
                      isFirstFour
                    />
                  </Box>
                }
              />
          </Box>
                    </RadioGroup>
          </FormControl>
          {isLocked && !isComplete && (
            <Box ml={1.5}>
              <Typography variant="subtitle2">
                In progress!
              </Typography>
            </Box>
          )}
          {isLocked && isComplete && (
            <Box ml={1.5}>
              <Typography variant="subtitle2">
                Game complete
              </Typography>
            </Box>
          )}
          {!isLocked && startDate && (
            <Typography variant="subtitle2">
              <Box ml={1.5}>
                <Typography variant="subtitle2">
                  {startDate}
                </Typography>
                <Typography variant="subtitle2">
                  {startTime}
                </Typography>
              </Box>
            </Typography>
          )}
        </Box>
      </Paper>
    );
  }
  else {
    return (
      <Paper
        className={bracketLinesClassName}
      >
        <PickRow
          team={team1Picked}
          isWinner={actualWinnerId === team1PickedId}
          isPickedToWin={pickedWinnerId === team1PickedId}
          isComplete={isComplete}
          isInGame={team1PickedId === team1Id}
        />
        <Box px={1} py={0.5}>
          <TeamRow
            team={team1}
            score={team1Score}
            isWinner={actualWinnerId === team1Id}
            isPickedToWin={pickedWinnerId === team1Id}
            isComplete={isComplete}
            isUpset={isUpset}
          />
          <TeamRow
            team={team2}
            score={team2Score}
            isWinner={actualWinnerId === team2Id}
            isPickedToWin={pickedWinnerId === team2Id}
            isComplete={isComplete}
            isUpset={isUpset}
          />
        </Box>
        <PickRow
          team={team2Picked}
          isWinner={actualWinnerId === team2PickedId}
          isPickedToWin={pickedWinnerId === team2PickedId}
          isComplete={isComplete}
          isInGame={team2PickedId === team2Id}
        />
      </Paper>
    );
  }
}; 

export default Game;
