import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, } from "react-router-dom";

import { get } from "lodash";

import {
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase'

import { auth, firestoreConstants, } from "../../firebase";

import {
  Box,
  Paper,
  Hidden,
  Typography,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import { fade } from "@material-ui/core/styles/colorManipulator";

import MUIDataTable from "mui-datatables";

import RankChangeCell from "../RankChangeCell";

import DisplayNameCell from "../DisplayNameCell";

import WinnerCell from "../WinnerCell";

import Loader from "../Loader";

import appearance from "../../services/appearance";

import {
  selectCurrentSeason,
} from '../../features/navigation/navigationSlice';

const styles = (theme) => ({
  myEntry: {
    backgroundColor: fade(appearance.colors.yellow.import[500], 0.2),
  },
  teamWon: {
    backgroundColor: "#badfbb",
  },
  eliminated: {
    backgroundColor: "#fedbd8",
  },
  title: {
    paddingTop: theme.spacing(2),
  },
});

const roundColumnInfo = {
  "firstFour": {
    label: "First Four",
    name: "first4Points",
  },
  64: {
    label: "R64",
    name: "firstRoundPoints",
  },
  32: {
    label: "R32",
    name: "secondRoundPoints",
  },
  16: {
    label: "S16",
    name: "sweet16Points",
  },
  8: {
    label: "E8",
    name: "elite8Points",
  },
  4: {
    label: "F4",
    name: "final4Points",
  },
  2: {
    label: "NCG",
    name: "championshipPoints",
  },
}

class StandingsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTabIndex: 0,
    };
  }
  
  sortWinners = (order) => {
    return (obj1, obj2) => {
      const name1 = get(obj1, ["name"]);
      const name2 = get(obj2, ["name"]);

      if (obj1 === obj2) {
        return 0;
      }

      if (!name1) {
        return 1;
      }

      if (!name2) {
        return -1;
      }

      if (order === "asc") {
        return name1.localeCompare(name2);
      }

      if (order === "desc") {
        return name2.localeCompare(name1);
      }
    }
  }


  getWinnerBackgroundClassName = (isWinner, isEliminated) => {
    const { classes } = this.props;

    if (isWinner) {
      return classes.teamWon;
    } else if (isEliminated) {
      return classes.eliminated;
    }

    return "";
  };

  getDisplayName = (dbUser) => {
    const {
      id,
      uid,
      firstName,
      lastName,
    } = dbUser;

    let realName = firstName;

    if (lastName) {
      realName = realName + " " + lastName;
    }

    return realName || uid || id;
  }

  getColumnsBySeason = (season) => {
    const {
      baseUrl,
    } = this.props;

    const {
      id: seasonId,
      currentRound,
    } = season;
    const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];
    const roundsToUse = roundsInOrder.slice(0, roundsInOrder.indexOf(currentRound) + 1)

    switch (seasonId) {
      case "2022": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Playins', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Total Upset Pts', name: 'totalUpsetPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "2023": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'First Four', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          // { label: 'Total Upset Pts', name: 'totalUpsetPoints', type: 'numeric', options: {
          //   sortDescFirst: true, 
          //   sortThirdClickReset: true, 
          // }},
        ]
      }
      case "2024": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'First Four', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "2025": {
        const perRoundPoints = roundsToUse.map(round => ({
          label: roundColumnInfo[round].label,
          name: roundColumnInfo[round].name,
          type: 'numeric',
          options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          },
        }));
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          ...(roundsToUse.length > 1 ? [
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          }] : []),
          ...perRoundPoints,
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
    }
  }

  getColumnsBySeasonMobile = (season) => {
    const {
      baseUrl,
    } = this.props;

    const {
      id: seasonId,
      currentRound,
    } = season;
    const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];
    const roundsToUse = roundsInOrder.slice(0, roundsInOrder.indexOf(currentRound) + 1)

    switch (seasonId) {
      case "2022": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'Playins', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Total Upset Pts', name: 'totalUpsetPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "2023": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'First Four', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          // { label: 'Total Upset Pts', name: 'totalUpsetPoints', type: 'numeric', options: {
          //   sortDescFirst: true, 
          //   sortThirdClickReset: true, 
          // }},
        ]
      }
      case "2024": {
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          },
          { label: 'First Four', name: 'first4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R64', name: 'firstRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          } },
          { label: 'R32', name: 'secondRoundPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'S16', name: 'sweet16Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'E8', name: 'elite8Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'F4', name: 'final4Points', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'NCG', name: 'championshipPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
        ]
      }
      case "2025": {
        const perRoundPoints = roundsToUse.map(round => ({
          label: roundColumnInfo[round].label,
          name: roundColumnInfo[round].name,
          type: 'numeric',
          options: {
            sortDescFirst: true, 
            sortThirdClickReset: true,
          },
        }));
        return [
          { label: 'Original Data', name: 'originalData', options: {
            display: false,
            filter: false,
            sort: false,
          }},
          { label: 'Rank', name: 'rank', type: 'numeric', options: {
            sortThirdClickReset: true, 
          }},
          { label: 'Total', name: 'overallPoints', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          { label: 'Bracket, Owner', name: 'displayName', options: {
            sortThirdClickReset: true, 
              customBodyRender: ((value, tableMeta) => (
                <DisplayNameCell
                  displayName={value}
                  userEntryId={tableMeta.rowData[0].userEntryId}
                  baseUrl={baseUrl}
                />
              )),
            }
          },
          { label: 'Champion', name: 'winner',
            options: {
              sortCompare: this.sortWinners,
              customBodyRender: ((value, tableMeta) => (
                <WinnerCell
                  winner={tableMeta.rowData[0].winner}
                  value={value}
                />
              )),
              setCellProps: (value) => {
                const winner = value.props.value;
                return {
                  className: this.getWinnerBackgroundClassName(
                    winner.isChampion,
                    winner.isEliminated,
                  )
                }
              }
            }
          },
          ...(roundsToUse.length > 1 ? [
          { label: '1-Round Change', name: 'rankChange', 
            options: {
              sortDescFirst: true, 
              sortThirdClickReset: true, 
              customBodyRender: ((value) => (
                <RankChangeCell
                  rankChange={value}
                />
              )),
            }
          }] : []),
          ...perRoundPoints,
          { label: 'Upsets Correct', name: 'upsetsCorrect', type: 'numeric', options: {
            sortDescFirst: true, 
            sortThirdClickReset: true, 
          }},
          // { label: 'Total Upset Pts', name: 'totalUpsetPoints', type: 'numeric', options: {
          //   sortDescFirst: true, 
          //   sortThirdClickReset: true, 
          // }},
        ]
      }
    }
  }

  transformUserEntriesBySeason = (userEntries, season) => {
    const {
      id: seasonId,
    } = season;

    switch (seasonId) {
      case "2022": {
        const {
          standings,
          teams,
        } = season;

        const {
          byRound,
        } = standings;

        const currentRoundStandings = byRound[2];
        const previousRoundStandings = byRound[4];

        return currentRoundStandings
            .map((standingsEntry) => ({ 
              ...standingsEntry,
              ...userEntries[standingsEntry.id],
            }))
            .map((userEntry) => {

          const {
            rank,
            ownerName,
            bracketName,
            scoring,
            totalUpsetPoints,
            upsetsCorrect,
            picksByGame,
            userId,
          } = userEntry;

          const winningTeamId = picksByGame[62].winner;
          const winningTeam = teams[winningTeamId];

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const first4Points = get(scoring, ["byRound", 128, "points"]) ?? 0;
          const firstRoundPoints = get(scoring, ["byRound", 64, "points"]) ?? 0;
          const secondRoundPoints = get(scoring, ["byRound", 32, "points"]) ?? 0;
          const sweet16Points = get(scoring, ["byRound", 16, "points"]) ?? 0;
          const elite8Points = get(scoring, ["byRound", 8, "points"]) ?? 0;
          const final4Points = get(scoring, ["byRound", 4, "points"]) ?? 0;
          const championshipPoints = get(scoring, ["byRound", 2, "points"]) ?? 0;

          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousRoundStandings);
          const rankChange = previousWeekRank - rank;

          const displayName = `${bracketName}, ${ownerName}`;

          let gridData = {
            userEntryId: userEntry.id,
            winner: winningTeam,
            overallPoints: overallPoints,
            first4Points: first4Points,
            firstRoundPoints: firstRoundPoints,
            secondRoundPoints: secondRoundPoints,
            sweet16Points: sweet16Points,
            elite8Points: elite8Points,
            final4Points: final4Points,
            championshipPoints: championshipPoints,
            rank: rank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
            totalUpsetPoints: totalUpsetPoints,
            upsetsCorrect: upsetsCorrect,
            userId: userId,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "2023": {
        const {
          standings,
          teams,
          currentRound,
        } = season;

        const {
          byRound,
        } = standings;

        const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];

        const currentRoundStandings = byRound[currentRound];
        const previousRoundStandings = byRound[roundsInOrder[roundsInOrder.indexOf(currentRound) - 1]];

        return currentRoundStandings
            .map((standingsEntry) => ({ 
              ...standingsEntry,
              ...userEntries[standingsEntry.id],
            }))
            .map((userEntry) => {

          const {
            rank,
            ownerName,
            name,
            bracketName,
            scoring,
            upsets,
            picksByGame,
            userId,
          } = userEntry;

          const winningTeamId = picksByGame[62].winner;
          const winningTeam = teams[winningTeamId];

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const first4Points = get(scoring, ["byRound", "firstFour", "points"]) ?? 0;
          const firstRoundPoints = get(scoring, ["byRound", 64, "points"]) ?? 0;
          const secondRoundPoints = get(scoring, ["byRound", 32, "points"]) ?? 0;
          const sweet16Points = get(scoring, ["byRound", 16, "points"]) ?? 0;
          const elite8Points = get(scoring, ["byRound", 8, "points"]) ?? 0;
          const final4Points = get(scoring, ["byRound", 4, "points"]) ?? 0;
          const championshipPoints = get(scoring, ["byRound", 2, "points"]) ?? 0;

          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousRoundStandings);
          const rankChange = previousWeekRank - rank;

          const displayName = name ? `${bracketName}, ${name}` : `${bracketName}, ${ownerName}`;

          let gridData = {
            userEntryId: userEntry.id,
            winner: winningTeam,
            overallPoints: overallPoints,
            first4Points: first4Points,
            firstRoundPoints: firstRoundPoints,
            secondRoundPoints: secondRoundPoints,
            sweet16Points: sweet16Points,
            elite8Points: elite8Points,
            final4Points: final4Points,
            championshipPoints: championshipPoints,
            rank: rank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
            // totalUpsetPoints: totalUpsetPoints,
            upsetsCorrect: upsets.length,
            userId: userId,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "2024": {
        const {
          standings,
          teams,
          currentRound,
        } = season;

        const {
          byRound,
        } = standings;

        const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];

        const currentRoundStandings = byRound[currentRound];
        const previousRoundStandings = byRound[roundsInOrder[roundsInOrder.indexOf(currentRound) - 1]];

        return currentRoundStandings
            .map((standingsEntry) => ({ 
              ...standingsEntry,
              ...userEntries[standingsEntry.id],
            }))
            .map((userEntry) => {

          const {
            rank,
            ownerName,
            name,
            bracketName,
            scoring,
            upsets,
            picksByGame,
            userId,
          } = userEntry;

          const winningTeamId = picksByGame[62].winner;
          const winningTeam = teams[winningTeamId];

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const first4Points = get(scoring, ["byRound", "firstFour", "points"]) ?? 0;
          const firstRoundPoints = get(scoring, ["byRound", 64, "points"]) ?? 0;
          const secondRoundPoints = get(scoring, ["byRound", 32, "points"]) ?? 0;
          const sweet16Points = get(scoring, ["byRound", 16, "points"]) ?? 0;
          const elite8Points = get(scoring, ["byRound", 8, "points"]) ?? 0;
          const final4Points = get(scoring, ["byRound", 4, "points"]) ?? 0;
          const championshipPoints = get(scoring, ["byRound", 2, "points"]) ?? 0;

          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousRoundStandings);
          const rankChange = previousWeekRank - rank;

          const displayName = name ? `${bracketName}, ${name}` : `${bracketName}, ${ownerName}`;

          let gridData = {
            userEntryId: userEntry.id,
            winner: winningTeam,
            overallPoints: overallPoints,
            first4Points: first4Points,
            firstRoundPoints: firstRoundPoints,
            secondRoundPoints: secondRoundPoints,
            sweet16Points: sweet16Points,
            elite8Points: elite8Points,
            final4Points: final4Points,
            championshipPoints: championshipPoints,
            rank: rank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
            // totalUpsetPoints: totalUpsetPoints,
            upsetsCorrect: upsets.length,
            userId: userId,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
      case "2025": {
        const {
          standings,
          teams,
          currentRound,
        } = season;

        const {
          byRound,
        } = standings;

        const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];

        const currentRoundStandings = byRound[currentRound];
        const previousRoundStandings = byRound[roundsInOrder[roundsInOrder.indexOf(currentRound) - 1]];

        return currentRoundStandings
            .map((standingsEntry) => ({ 
              ...standingsEntry,
              ...userEntries[standingsEntry.id],
            }))
            .map((userEntry) => {

          const {
            rank,
            ownerName,
            name,
            bracketName,
            scoring,
            upsets,
            picksByGame,
            userId,
          } = userEntry;

          const winningTeamId = picksByGame[62].winner;
          const winningTeam = teams[winningTeamId];

          const overallPoints = get(scoring, ["overall"]) ?? 0;
          const first4Points = get(scoring, ["byRound", "firstFour", "points"]) ?? 0;
          const firstRoundPoints = get(scoring, ["byRound", 64, "points"]) ?? 0;
          const secondRoundPoints = get(scoring, ["byRound", 32, "points"]) ?? 0;
          const sweet16Points = get(scoring, ["byRound", 16, "points"]) ?? 0;
          const elite8Points = get(scoring, ["byRound", 8, "points"]) ?? 0;
          const final4Points = get(scoring, ["byRound", 4, "points"]) ?? 0;
          const championshipPoints = get(scoring, ["byRound", 2, "points"]) ?? 0;

          const previousWeekRank = this.getEntryRankInStandings(userEntry, previousRoundStandings);
          const rankChange = previousWeekRank - rank;

          const displayName = name ? `${bracketName}, ${name}` : `${bracketName}, ${ownerName}`;

          let gridData = {
            userEntryId: userEntry.id,
            winner: winningTeam,
            overallPoints: overallPoints,
            first4Points: first4Points,
            firstRoundPoints: firstRoundPoints,
            secondRoundPoints: secondRoundPoints,
            sweet16Points: sweet16Points,
            elite8Points: elite8Points,
            final4Points: final4Points,
            championshipPoints: championshipPoints,
            rank: rank,
            previousRank: previousWeekRank,
            rankChange: rankChange,
            displayName: displayName,
            // totalUpsetPoints: totalUpsetPoints,
            upsetsCorrect: upsets.length,
            userId: userId,
          };

          gridData = {
            originalData: {...gridData},
            ...gridData,
          };

          return gridData;
        });
      }
    }
  };


  isLoaded = () => {
    const {
      seasons,
      userEntries,
    } = this.props;

    if (this.props.user) {
      return isLoaded(
        seasons,
        userEntries,
      );
    } else {
      return isLoaded(
        seasons,
        userEntries,
      );
    }
  }

  isUserSetup = () => {
    const {
      user,
    } = this.props;

    return user && (user.firstName || user.lastName);
  };

  getEntryRankInStandings = (userEntry, standings) => {
    const {
      id,
    } = userEntry;

    return standings.find((standingsEntry) => standingsEntry.id === id).rank;
  };

  render() {
    // Styling
    const { classes } = this.props;

    const {
      user,
      seasons,
      userEntries,
    } = this.props;

    const uid = get(user, "uid");

    const params = get(this.props, "match.params");
    const {
      seasonId,
    } = params;

    if (!this.isLoaded()) {
      return <Loader />;
    }

    const season = seasons[seasonId];

    const {
      hasPersonalStandings,
    } = season;

    const myUserEntries = Object.entries(userEntries)
      .filter(([id, entry]) => entry.userId && entry.userId === uid)
      .map(([id, entry]) => {
        return {
          ...entry,
          id: id,
        }})

    const hasEntry = myUserEntries.length > 0;

    const tableData = this.transformUserEntriesBySeason(userEntries, season);
    const tableColumns = this.getColumnsBySeason(season);
    const mobileColumns = this.getColumnsBySeasonMobile(season);

    const myEntryData = hasEntry ? tableData.filter((row) => row.userId && row.userId === uid) : null;
    const myEntryTitleText = myUserEntries.length === 2 ? "My Entries:" : "My Entry:";
    const myEntryTitle = (
      <Typography
        variant="h6"
        className={classes.title}
        align="center"
      >
        {myEntryTitleText}
      </Typography>
    );

    const title = (
      <Typography
        variant="h6"
        className={classes.title}
        align="center"
      >
        Standings:
      </Typography>
    );

    return (
      <>
        <Hidden mdUp>
          {hasPersonalStandings && hasEntry && (
            <MUIDataTable
              title={myEntryTitle}
              columns={mobileColumns}
              data={myEntryData}
              options={{
                elevation: 0,
                pagination: false,
                download: false,
                print: false,
                filter: false,
                viewColumns: false,
                search: false,
                rowHover: false,
                tableBodyMaxHeight: "100%",
                selectableRows: "none",
                responsive: "standard",
                setRowProps: (row, dataIndex, rowIndex) => {
                  if (row[0].userId && row[0].userId === uid) {
                    return {
                      className: classes.myEntry,
                    }
                  }
                  return {};
                },
                setTableProps: () => {
                  return {
                    size: "small",
                  };
                },
              }}
            />
          )}
          <MUIDataTable
            title={title}
            columns={mobileColumns}
            data={tableData}
            options={{
              elevation: 0,
              pagination: false,
              download: false,
              print: false,
              filter: false,
              viewColumns: false,
              search: false,
              tableBodyMaxHeight: "100%",
              selectableRows: "none",
              responsive: "standard",
              setRowProps: (row, dataIndex, rowIndex) => {
                if (row[0].userId && row[0].userId === uid) {
                  return {
                    className: classes.myEntry,
                  }
                }
                return {};
              },
              setTableProps: () => {
                return {
                  size: "small",
                };
              },
            }}
          />
        </Hidden>
        <Hidden smDown>
          <Box p={2.5}>
            <Paper>
              <Box pt={2} px={4}>
                {hasPersonalStandings && hasEntry && (
                  <MUIDataTable
                    title={myEntryTitleText}
                    columns={tableColumns}
                    data={myEntryData}
                    options={{
                      elevation: 0,
                      pagination: false,
                      download: false,
                      print: false,
                      filter: false,
                      viewColumns: false,
                      search: false,
                      rowHover: false,
                      tableBodyMaxHeight: "100%",
                      selectableRows: "none",
                      responsive: "standard",
                      setRowProps: (row, dataIndex, rowIndex) => {
                        if (row[0].userId && row[0].userId === uid) {
                          return {
                            className: classes.myEntry,
                          }
                        }
                        return {};
                      },
                    }}
                  />
                )}
                <MUIDataTable
                  title="Standings:"
                  columns={tableColumns}
                  data={tableData}
                  options={{
                    elevation: 0,
                    pagination: false,
                    download: false,
                    print: false,
                    filter: false,
                    viewColumns: false,
                    search: false,
                    tableBodyMaxHeight: "100%",
                    selectableRows: "none",
                    responsive: "standard",
                    setRowProps: (row, dataIndex, rowIndex) => {
                      if (row[0].userId && row[0].userId === uid) {
                        return {
                          className: classes.myEntry,
                        }
                      }
                      return {};
                    },
                  }}
                />
              </Box>
            </Paper>
          </Box>
        </Hidden>
      </>
    );
  }
}

function mapStateToProps(state, props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return {
    seasons: state.firestore.data.seasons,
    userEntries: state.firestore.data[`${seasonId}#userEntries`],
  };
}

function registerFirestoreListeners(props) {
  const params = get(props, "match.params");
  const {
    seasonId,
  } = params;

  return [
    {
      collection: "seasons",
      doc: seasonId,
    },
    {
      collection: "seasons",
      doc: seasonId,
      subcollections: [{
        collection: "user_entries",
      }],
      storeAs: `${seasonId}#userEntries`,
    },
  ];
}

export default compose(
  withStyles(styles),
  withRouter,
  firestoreConnect(registerFirestoreListeners),
  connect(mapStateToProps),
)(StandingsPage);
