import React, { Component } from "react";

import {
  Box,
  Typography,
  Hidden,
  Badge,
  Tooltip,
} from "@material-ui/core";

import {
  Skeleton,
} from "@material-ui/lab";

import { makeStyles, withStyles, } from "@material-ui/core/styles";

import appearance from "../../services/appearance";

import { get } from "lodash";

const styles = (theme) => ({
});

const loserFontColor = "#999";

const useStyles = makeStyles((theme) => ({
  teamSeed: {
    marginLeft: theme.spacing(1),
    marginTop: "1px",
    fontSize: "11px",
    width: "11px",
    color: loserFontColor,
  },
  teamName: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
  },
  teamNameWon: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
  },
  teamNameCorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "green",
  },
  teamNameIncorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "red",
  },
  teamNameLoser: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    color: loserFontColor,
  },
  inProgressScore: {
    fontSize: "12px",
    marginLeft: theme.spacing(0.5),
    color: appearance.colors.gray.import[600],
  },
  winnerScore: {
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: theme.spacing(0.5),
  },
  winnerScoreUpset: {
    fontSize: "12px",
    fontWeight: "bold",
    zIndex: 2,
    marginLeft: theme.spacing(0.5),
  },
  loserScore: {
    color: appearance.colors.gray.import[600],
    fontSize: "12px",
    marginLeft: theme.spacing(0.5),
  },
  pickRow: {
    backgroundColor: appearance.colors.gray.import[100],
  },
  pickRowCorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid green",
  },
  pickRowIncorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid red",
  },
  noTeamIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  upsetBadgeIncorrect: {
    "& .MuiBadge-badge": {
      backgroundColor: appearance.colors.yellow.import["A100"],
    },
  },
  upsetBadgeCorrect: {
    "& .MuiBadge-badge": {
      backgroundColor: appearance.colors.green.import["A100"],
    },
  },
}));

const TeamRow = (props) => {
  const classes = useStyles();
  const {
    team,
    score,
    isWinner,
    isUpset,
    isPickedToWin,
    isFirstRound,
    isComplete,
    isFirstFour,
  } = props;

  const {
    name,
    abbreviation,
    seed,
    icon_path,
  } = team;

  const gameHasStarted = score > 0;

  if (isFirstRound) {
    return (
      <Box
        display="flex"
        alignItems="center"
        minHeight="24px"
      >
        {icon_path && (
            <img
              src={`/assets/icons/${icon_path}`}
              height={16}
              width={16}
            />
        )}
        {!icon_path && (
          <Skeleton
            variant="circle"
            animation={false}
            className={classes.noTeamIcon}
          />
        )}
        {seed && (
          <Typography
            className={classes.teamSeed}
            align={"right"}
          >
            {seed}
          </Typography>
        )}
        {isWinner && isPickedToWin && (
          <Typography
            className={classes.teamNameCorrect}
          >
            <Hidden smDown>
              {name}
            </Hidden>
            <Hidden mdUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {isWinner && !isPickedToWin && (
          <Typography
            className={classes.teamNameWon}
          >
            <Hidden smDown>
              {name}
            </Hidden>
            <Hidden mdUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {!isWinner && !isPickedToWin && (
          <Typography
            className={classes.teamNameLoser}
          >
            <Hidden smDown>
              {name}
            </Hidden>
            <Hidden mdUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {!isWinner && isPickedToWin && gameHasStarted && isComplete && (
          <Typography
            className={classes.teamNameIncorrect}
          >
            <Hidden smDown>
              {name}
            </Hidden>
            <Hidden mdUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {!isWinner && isPickedToWin && !isComplete && (
          <Typography
            className={classes.teamNameWon}
          >
            <Hidden smDown>
              {name}
            </Hidden>
            <Hidden mdUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {isUpset && isWinner && isComplete && (
          <Badge
            className={isPickedToWin ? classes.upsetBadgeCorrect : classes.upsetBadgeIncorrect}
            badgeContent= {
              <Tooltip title="Upset!">
                <span>!</span>
              </Tooltip>
            }
          >
            <Typography
              className={classes.winnerScoreUpset}
            >
              {score}
            </Typography>
          </Badge>
        )}
        {!(isUpset && isWinner) && isComplete && (
          <Typography
            className={isWinner ? classes.winnerScore : classes.loserScore}
          >
            {score}
          </Typography>
        )}
        {!isComplete && gameHasStarted && (
          <Typography
            className={classes.inProgressScore}
          >
            {score}
          </Typography>
        )}
      </Box>
    );
  }

  if (isFirstFour) {
    return (
      <Box
        display="flex"
        alignItems="center"
        minHeight="24px"
      >
        {icon_path && (
          <img
            src={`/assets/icons/${icon_path}`}
            height={16}
            width={16}
          />
        )}
        {!icon_path && (
          <Skeleton
            variant="circle"
            animation={false}
            className={classes.noTeamIcon}
          />
        )}
        {seed && (
          <Typography
            className={classes.teamSeed}
            align={"right"}
          >
            {seed}
          </Typography>
        )}
        {isComplete && (
          <Typography
            className={isWinner ? classes.teamNameWon : classes.teamNameLoser}
          >
            <Hidden xsDown>
              {abbreviation}
            </Hidden>
            <Hidden smUp>
              {abbreviation}
            </Hidden>
          </Typography>
        )}
        {!isComplete && (
          <Typography
            className={classes.teamName}
          >
            {name}
          </Typography>
        )}
        {isComplete && isUpset && isWinner && (
          <Badge
            className={isPickedToWin ? classes.upsetBadgeCorrect : classes.upsetBadgeIncorrect}
            badgeContent= {
              <Tooltip title="Upset!">
                <span>!</span>
              </Tooltip>
            }
          >
            <Typography
              className={classes.winnerScoreUpset}
            >
              {score}
            </Typography>
          </Badge>
        )}
        {isComplete && !(isUpset && isWinner) && (
          <Typography
            className={isWinner ? classes.winnerScore : classes.loserScore}
          >
            {score}
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      minHeight="24px"
    >
      {icon_path && (
        <img
          src={`/assets/icons/${icon_path}`}
          height={16}
          width={16}
        />
      )}
      {!icon_path && (
        <Skeleton
          variant="circle"
          animation={false}
          className={classes.noTeamIcon}
        />
      )}
      {seed && (
        <Typography
          className={classes.teamSeed}
          align={"right"}
        >
          {seed}
        </Typography>
      )}
      {isComplete && (
        <Typography
          className={isWinner ? classes.teamNameWon : classes.teamNameLoser}
        >
          <Hidden smDown>
            {name}
          </Hidden>
          <Hidden mdUp>
            {abbreviation}
          </Hidden>
        </Typography>
      )}
      {!isComplete && (
        <Typography
          className={classes.teamName}
        >
          <Hidden smDown>
            {name}
          </Hidden>
          <Hidden mdUp>
            {abbreviation}
          </Hidden>
        </Typography>
      )}
      {isComplete && isUpset && isWinner && (
        <Badge
          className={isPickedToWin ? classes.upsetBadgeCorrect : classes.upsetBadgeIncorrect}
          badgeContent= {
            <Tooltip title="Upset!">
              <span>!</span>
            </Tooltip>
          }
        >
          <Typography
            className={classes.winnerScoreUpset}
          >
            {score}
          </Typography>
        </Badge>
      )}
      {isComplete && !(isUpset && isWinner) && (
        <Typography
          className={isWinner ? classes.winnerScore : classes.loserScore}
        >
          {score}
        </Typography>
      )}
      {!isComplete && gameHasStarted && (
        <Typography
          className={classes.inProgressScore}
        >
          {score}
        </Typography>
      )}
    </Box>
  );
};

export default TeamRow;
