import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import PropTypes from "prop-types";

import { Link as RouterLink } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Link,
  SvgIcon,
  Hidden,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import {
  toggleSidebar,
} from './navigationSlice';

import UserAvatar from "../../components/UserAvatar";

import { Menu as MenuIcon } from "@material-ui/icons";

import { ReactComponent as UMDIcon } from "../../assets/icons/umd_logo.svg";

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  icon: {
    height: '40px',
    width: '40px',
  },
});

function ElevationScroll(props) {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 6 : 2,
  });
}

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }
  
  toggleDrawer = (open) => (event) => {
    this.props.dispatch(toggleSidebar())
  };
  
  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties
    const { performingAction, user, userData, roles } = this.props;

    // Styling
    const { classes } = this.props;

    // Events
    const {
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;

    const menuItems = [
      // {
      //   name: "Settings",
      //   onClick: onSettingsClick,
      // },
      {
        name: "Sign out",
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    return (
      <ElevationScroll>
        <AppBar
          color="primary"
          position="fixed"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar disableGutters>
            <IconButton edge="end"
              onClick={this.toggleDrawer()}
            >
              <MenuIcon  style={{fill: "white"}}/>
            </IconButton>
            <IconButton
              component={RouterLink}
              to="/"
            >
              <SvgIcon component={UMDIcon} viewBox="0 0 152 152" htmlColor="black" className={classes.icon} />
            </IconButton>

            <Box display="flex" flexGrow={1}>
              <Typography color="inherit" variant="h6">
                <Link
                  color="inherit"
                  component={RouterLink}
                  to="/"
                  underline="none"
                >
                  {process.env.REACT_APP_TITLE}
                </Link>
              </Typography>
            </Box>

            {user && (
              <>
                {roles.includes("admin") && false && (
                  <Box mr={1}>
                    <Button
                      color="inherit"
                      component={RouterLink}
                      to="/admin"
                      variant="outlined"
                    >
                      Admin
                    </Button>
                  </Box>
                )}

                <IconButton
                  color="inherit"
                  disabled={performingAction}
                  onClick={this.openMenu}
                >
                  <UserAvatar user={Object.assign(user, userData)} />
                </IconButton>

                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.anchorEl)}
                  onClose={this.closeMenu}
                >
                  {menuItems.map((menuItem, index) => {
                    if (
                      menuItem.hasOwnProperty("condition") &&
                      !menuItem.condition
                    ) {
                      return null;
                    }

                    let component = null;

                    if (menuItem.to) {
                      component = (
                        <MenuItem
                          key={index}
                          component={RouterLink}
                          to={menuItem.to}
                          onClick={this.closeMenu}
                        >
                          {menuItem.name}
                        </MenuItem>
                      );
                    } else {
                      component = (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            this.closeMenu();

                            menuItem.onClick();
                          }}
                        >
                          {menuItem.name}
                        </MenuItem>
                      );
                    }

                    if (menuItem.divide) {
                      return (
                        <span key={index}>
                          <Divider />

                          {component}
                        </span>
                      );
                    }

                    return component;
                  })}
                </Menu>
              </>
            )}
            {!user && (
              <Box mr={1.5}>
                <ButtonGroup
                  color="inherit"
                  disabled={performingAction}
                  variant="outlined"
                >
                  <Button onClick={onSignUpClick}>Sign up</Button>
                  <Button onClick={onSignInClick}>Sign in</Button>
                </ButtonGroup>
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default compose(
  connect(),
  withStyles(styles),
)(Bar);
