import React, { Fragment, } from "react";

import {
  Grid,
} from "@material-ui/core";

import { makeStyles, } from "@material-ui/core/styles";

import appearance from "../../services/appearance";

import { Hidden } from "@material-ui/core";

import Game from "../Game";

const loserFontColor = "#999";

const useStyles = makeStyles((theme) => ({
  teamSeed: {
    marginLeft: theme.spacing(1),
    marginTop: "1px",
    fontSize: "11px",
    width: "11px",
    color: loserFontColor,
  },
  teamName: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
  },
  teamNameWon: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
  },
  teamNameCorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "green",
  },
  teamNameIncorrect: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    fontWeight: 500,
    color: "red",
  },
  teamNameLoser: {
    flexGrow: 1,
    marginLeft: theme.spacing(0.5),
    fontSize: "12px",
    color: loserFontColor,
  },
  winnerScore: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  loserScore: {
    color: appearance.colors.gray.import[600],
    fontSize: "12px",
  },
  pickRow: {
    backgroundColor: appearance.colors.gray.import[100],
  },
  pickRowCorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid green",
  },
  pickRowIncorrect: {
    backgroundColor: appearance.colors.gray.import[100],
    borderLeft: "5px solid red",
  },
  bracketLineFirstRoundLeft: {
    position: "relative",
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderRight: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
  bracketLineLaterRoundLeft: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "53%",
      borderRight: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
  bracketLineSweet16Left: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      zIndex: -1,
      content: '""',
      display: "block",
      width: "1em",
      height: "120%",
      borderRight: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
  bracketLineFirstRoundRight: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    "&::after": {
      position: "absolute",
      left: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderLeft: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopLeftRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomLeftRadius: "0.5em"
    },
  },
  bracketLineLaterRoundRight: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      left: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "53%",
      borderLeft: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopLeftRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomLeftRadius: "0.5em"
    },
  },
  bracketLineSweet16Right: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      zIndex: -1,
      left: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "120%",
      borderLeft: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopLeftRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomLeftRadius: "0.5em"
    },
  },
  bracketLineElite8Top: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      zIndex: -1,
      content: '""',
      display: "block",
      width: "1em",
      height: "260%",
      borderRight: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
  },
  bracketLineElite8Bottom: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      zIndex: -1,
      content: '""',
      display: "block",
      width: "1em",
      height: "260%",
      borderRight: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
  bracketLineGamesComingInLeft: {
    position: "relative",
    "&::before": {
      position: "absolute",
      left: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderLeft: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translate(calc(-1em - 2px), 1px)",
      borderBottomLeftRadius: "0.5em"
    },
    "&::after": {
      position: "absolute",
      left: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderLeft: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translate(calc(-1em - 2px), -1px)",
      borderTopLeftRadius: "0.5em"
    },
  },
  bracketLineGamesComingInRight: {
    position: "relative",
    "&::before": {
      position: "absolute",
      right: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderRight: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translate(calc(1em + 2px), 1px)",
      borderBottomRightRadius: "0.5em"
    },
    "&::after": {
      position: "absolute",
      right: 0,
      zIndex: 1,
      content: '""',
      display: "block",
      width: "1em",
      height: "30%",
      borderRight: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translate(calc(1em + 2px), -1px)",
      borderTopRightRadius: "0.5em"
    },
  },
  elite8LeftTop: {
    marginLeft: "-200%",
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      zIndex: -1,
      position: "absolute",
      right: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "400%",
      borderRight: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
  },
  elite8LeftBottom: {
    marginLeft: "-200%",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    position: "relative",
    "&::after": {
      zIndex: -1,
      position: "absolute",
      right: 0,
      content: '""',
      display: "block",
      height: "400%",
      width: "1em",
      borderRight: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
  elite8RightTop: {
    marginRight: "-200%",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    position: "relative",
    "&::after": {
      zIndex: -1,
      height: "400%",
      position: "absolute",
      left: 0,
      content: '""',
      display: "block",
      width: "1em",
      borderLeft: "2px solid #bdbdbd",
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopLeftRadius: "0.5em"
    },
  },
  elite8RightBottom: {
    marginRight: "-200%",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    position: "relative",
    "&::after": {
      zIndex: -1,
      position: "absolute",
      left: 0,
      content: '""',
      display: "block",
      height: "400%",
      width: "1em",
      borderLeft: "2px solid #bdbdbd",
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomLeftRadius: "0.5em",
    },
  },
  bracketLineFinal4: {
    position: "relative",
    paddingLeft: "1em",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: "1em",
    "&::after": {
      position: "absolute",
      right: 0,
      content: '""',
      display: "block",
      width: "1em",
      height: "530%",
      borderRight: "2px solid #bdbdbd",
    },
    "&:nth-child(odd)::after": {
      top: "50%",
      borderTop: "2px solid #bdbdbd",
      transform: "translateY(-1px)",
      borderTopRightRadius: "0.5em"
    },
    "&:nth-child(even)::after": {
      bottom: "50%",
      borderBottom: "2px solid #bdbdbd",
      transform: "translateY(1px)",
      borderBottomRightRadius: "0.5em"
    },
  },
}));

const getGameComingInBracketLinesClassName = (classes, isMobile, alignment, isFirstRound, round) => {
  const desktopRoundsComingIn = [32, 16,];
  const mobileRoundsComingIn = [32, 16, 8, 4, 2,];

  let requiredClasses = [];
  if (!isMobile) {
    if (alignment === "left" && desktopRoundsComingIn.includes(round)) {
      requiredClasses.push(classes.bracketLineGamesComingInLeft);
    } else if (alignment === "right" && desktopRoundsComingIn.includes(round)) {
      requiredClasses.push(classes.bracketLineGamesComingInRight);
    }
  } else {
    if (mobileRoundsComingIn.includes(round)) {
      requiredClasses.push(classes.bracketLineGamesComingInLeft);
    }
  }

  return requiredClasses.join(" ");
};

const getGameBracketLinesClassName = (classes, isMobile, alignment, verticalAlignment, isFirstRound, round, region) => {
  const roundsLeavingLines = [32];

  let requiredClasses = [];
  if (!isMobile) {
    if (alignment === "left" && verticalAlignment === "top" && round === 8) {
      requiredClasses.push(classes.elite8LeftTop);
    }
    if (alignment === "left" && verticalAlignment === "bottom" && round === 8) {
      requiredClasses.push(classes.elite8LeftBottom);
    }
    if (alignment === "right" && verticalAlignment === "top" && round === 8) {
      requiredClasses.push(classes.elite8RightTop);
    }
    if (alignment === "right" && verticalAlignment === "bottom" && round === 8) {
      requiredClasses.push(classes.elite8RightBottom);
    }
    if (alignment === "left" && round === 16) {
      requiredClasses.push(classes.bracketLineSweet16Left);
    }
    if (alignment === "right" && round === 16) {
      requiredClasses.push(classes.bracketLineSweet16Right);
    }
    if (alignment === "left" && isFirstRound) {
      requiredClasses.push(classes.bracketLineFirstRoundLeft);
    } 
    if (alignment === "left" && roundsLeavingLines.includes(round)) {
      requiredClasses.push(classes.bracketLineLaterRoundLeft);
    } 
    if (alignment === "right" && isFirstRound) {
      requiredClasses.push(classes.bracketLineFirstRoundRight);
    } 
    if (alignment === "right" && roundsLeavingLines.includes(round)) {
      requiredClasses.push(classes.bracketLineLaterRoundRight);
    }
  } else {
    if (alignment === "left" && round === 4) {
      requiredClasses.push(classes.bracketLineFinal4);
    }
    if (alignment === "left" && verticalAlignment === "top" && round === 8) {
      requiredClasses.push(classes.bracketLineElite8Top);
    }
    if (alignment === "left" && verticalAlignment === "bottom" && round === 8) {
      requiredClasses.push(classes.bracketLineElite8Bottom);
    }
    if (alignment === "left" && round === 16) {
      requiredClasses.push(classes.bracketLineSweet16Left);
    }
    if (alignment === "right" && round === 16) {
      requiredClasses.push(classes.bracketLineSweet16Right);
    }
    if (alignment === "left" && isFirstRound) {
      requiredClasses.push(classes.bracketLineFirstRoundLeft);
    } 
    if (alignment === "left" && roundsLeavingLines.includes(round)) {
      requiredClasses.push(classes.bracketLineLaterRoundLeft);
    } 
    if (alignment === "right" && isFirstRound) {
      requiredClasses.push(classes.bracketLineFirstRoundRight);
    } 
    if (alignment === "right" && roundsLeavingLines.includes(round)) {
      requiredClasses.push(classes.bracketLineLaterRoundRight);
    }
  }
  return requiredClasses.join(" ");
};

const BracketColumn = (props) => {
  const classes = useStyles();

  const {
    gamesInColumn,
    games,
    teams,
    picksByGame,
    alignment,
    verticalAlignment,
    round,
    isFirstRound,
    region,
  } = props;
  
  return (
    <>
      {gamesInColumn.map((gameId) => {
        const game = games[gameId];

        const {
          team1Id: team1IdActual,
          team2Id: team2IdActual,
        } = game;

        const pick = picksByGame[gameId];

        const {
          team1Id: team1IdPicked,
          team2Id: team2IdPicked,
        } = pick;

        const team1 = teams[team1IdActual];
        const team2 = teams[team2IdActual];
        const team1Picked = teams[team1IdPicked];
        const team2Picked = teams[team2IdPicked];

        return (
          <Fragment key={gameId}>
            <Hidden smDown>
              <Grid item
                className={getGameBracketLinesClassName(classes, false, alignment, verticalAlignment, isFirstRound, round, region)}
              >
                <Game
                  game={game}
                  pick={pick}
                  team1={team1}
                  team2={team2}
                  team1Picked={team1Picked}
                  team2Picked={team2Picked}
                  team1PickedId={team1IdPicked}
                  team2PickedId={team2IdPicked}
                  isFirstRound={isFirstRound}
                  bracketLinesClassName={getGameComingInBracketLinesClassName(classes, false, alignment, isFirstRound, round)}
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item
                className={getGameBracketLinesClassName(classes, true, alignment, verticalAlignment, isFirstRound, round, region)}
              >
                <Game
                  game={game}
                  pick={pick}
                  team1={team1}
                  team2={team2}
                  team1Picked={team1Picked}
                  team2Picked={team2Picked}
                  team1PickedId={team1IdPicked}
                  team2PickedId={team2IdPicked}
                  isFirstRound={isFirstRound}
                  bracketLinesClassName={getGameComingInBracketLinesClassName(classes, true, alignment, isFirstRound, round)}
                />
              </Grid>
            </Hidden>
          </Fragment>
        )
      })}
    </>
  )
};

export default BracketColumn;
