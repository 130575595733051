import { firestore, } from "../firebase";

import {
  currentSeason,
} from "../data/season-constants";

import { get } from "lodash";

const gameService = {};

gameService.updateGame = async (game, openSnackbar) => {
  return new Promise(async (resolve, reject) => {
    const seasonRef = firestore.collection("seasons").doc(currentSeason);
    const seasonDoc = await seasonRef.get();
    const seasonData = seasonDoc.data();

    const firstFourGameIds = [63, 64, 65, 66];
    const roundsInOrder = ["firstFour", 64, 32, 16, 8, 4, 2];
    const userScores = [];

    const {
      games,
      rounds,
      teams,
      standings,
      currentRound,
      upsets = [],
    } = seasonData;

    const {
      id: gameId,
      team1Id,
      team1Score,
      team2Id,
      team2Score,
      nextGameId,
      isTeam1Next,
      round,
    } = game;

    const currentSeasonRoundIndex = roundsInOrder.indexOf(currentRound);
    const thisRoundIndex = roundsInOrder.indexOf(round);
    const shouldUpdateRound = currentSeasonRoundIndex < thisRoundIndex;

    const isFirstFourGame = firstFourGameIds.includes(gameId);
    const roundsToUse = roundsInOrder.slice(0, roundsInOrder.indexOf(round) + 1)

    const team1 = teams[team1Id];
    const team2 = teams[team2Id];

    const {
      seed: seed1,
    } = team1;

    const {
      seed: seed2,
    } = team2;

    const winnerId = team1Score > team2Score ? team1Id : team2Id;

    let isUpset = false;
    if (winnerId === team1Id && (seed1 - seed2 >= 3)) {
      isUpset = true;
    } else if (winnerId === team2Id && (seed2 - seed1 >= 3)) {
      isUpset = true;
    }

    let updatedTeams = {
      ...teams,
    };

    if (winnerId === team1Id) {
      const updatedTeam2 = {
        ...team2,
        isEliminated: true,
      };
      updatedTeams[team2Id] = updatedTeam2;
    } else {
      const updatedTeam1 = {
        ...team1,
        isEliminated: true,
      };
      updatedTeams[team1Id] = updatedTeam1;
    }

    const updatedUpsetList = upsets.filter(id => id !== gameId);
    if (isUpset) {
      updatedUpsetList.push(gameId);
    }

    const updatedGame = {
      ...game,
      winnerId: winnerId,
      isUpset: isUpset,
      isComplete: true,
    };

    let updatedGames = {
      ...games,
      [gameId]: updatedGame,
    };

    if (!isFirstFourGame && nextGameId) {
      let updatedNextGame = {
        ...games[nextGameId],
      };

      if (isTeam1Next) {
        updatedNextGame.team1Id = winnerId;
      } else {
        updatedNextGame.team2Id = winnerId;
      }

      updatedGames = {
        ...updatedGames,
        [nextGameId]: updatedNextGame,
      };
    }

    const roundData = rounds[round];

    const {
      pointsPerGame,
    } = roundData;

    const batch = firestore.batch();

    seasonRef.collection("user_entries").get().then((snapshot) => {
      snapshot.forEach((doc) => {
        const userEntrySnapshot = doc.data();
  
        const {
          picksByGame,
          scoring,
          upsetsCorrect = 0,
          totalUpsetPoints = 0,
          upsets: userUpsets = [],
          firstFourPicks,
        } = userEntrySnapshot;
  
        if (picksByGame || (isFirstFourGame && get(firstFourPicks, gameId))) {

          let winner = "";
          if (isFirstFourGame) {
            winner = get(firstFourPicks, gameId)
          } else {
            const pickForGame = picksByGame[gameId];

            const {
              winner: winnerPick,
            } = pickForGame;
            winner = winnerPick;
          }

          let pointsScored = 0;
          let updatedUpsetsCorrect = upsetsCorrect;
          let updatedTotalUpsetPoints = totalUpsetPoints;
          const userUpdatedUpsetList = userUpsets.filter(id => id !== gameId);

          if (winnerId === winner) {
            pointsScored = isUpset ? pointsPerGame * 2 : pointsPerGame;

            if (isUpset) {
              updatedUpsetsCorrect += 1;
              updatedTotalUpsetPoints += pointsPerGame;
              userUpdatedUpsetList.push(gameId);
            }
          }

          const previousOverallPoints =
            (get(scoring, ["overall"]) ?? 0);

          const previousRoundPoints =
            (get(scoring, ["byRound", round, "points"]) ?? 0);
    
          const previousGamePoints =
            (get(scoring, ["byGame", gameId]) ?? 0)

          const scoringByRound = get(scoring, "byRound") ?? {};
          const scoringByGame = get(scoring, "byGame") ?? {};

          const updatedOverallPoints = previousOverallPoints -
            previousGamePoints + pointsScored;
    
          const updatedRoundPoints = previousRoundPoints -
            previousGamePoints + pointsScored;

          const updatedScoring = {
            ...scoring,
            overall: updatedOverallPoints,
            byRound: {
              ...scoringByRound,
              [round]: {
                points: updatedRoundPoints,
              },
            },
            byGame: {
              ...scoringByGame,
              [gameId]: pointsScored,
            },
          };

          const score = roundsToUse.map((round) => get(updatedScoring, ["byRound", round, "points"]) ?? 0)
            .reduce((a, b) => a + b);
          userScores.push({
            id: doc.id,
            score: score,
          });

          batch.set(doc.ref, 
            { 
              scoring: updatedScoring,
              upsets: userUpdatedUpsetList,
            },
            { merge: true }
          );
        }
      });
    }).catch((reason) => {
      reject(reason);
    }).then(async () => {
      const sortedUserScores = sortUserScores(userScores);
      console.log(sortedUserScores);

      let currentRank = 1;
      const allEntriesBreakTies = [];
      sortedUserScores.forEach((entry, index) => {
        if (index !== 0) {
          if (entry.score !==
              sortedUserScores[index - 1].score) {
            currentRank = index + 1;
          }
        }
        allEntriesBreakTies.push({
          ...entry,
          rank: currentRank,
        });
      });

      const updatedRoundStandings = {
        ...standings,
        byRound: {
          ...standings.byRound,
          [round]: allEntriesBreakTies,
        },
      };

      batch.set(seasonRef, 
        { 
          games: updatedGames,
          teams: updatedTeams,
          upsets: updatedUpsetList,
          standings: updatedRoundStandings,
          ...(shouldUpdateRound && {currentRound: round})
        },
        { merge: true }
      );
      console.log(updatedRoundStandings);

      await batch.commit()
        .then(() => {
          openSnackbar("Saved game!", "success")
        })
        .catch((reason) => {
          reject(reason);
        });
    })
  });
};

const sortUserScores = (userScores) => {
  return userScores.sort(function(a, b) {
    return b.score - a.score;
  });
};

export default gameService;